import expressions from 'angular-expressions';

export function diffRecursive(previous, current, path = '') {
  if (Array.isArray(current)) {
    for (let idx = 0; idx < current.length; idx++) {
      const subPath = path + '[' + idx + ']';
      if (diffRecursive(previous, current[idx], subPath)) {
        return true;
      }
    }
    return false;
  } else
  if ('object' === typeof current) {
    for (const k in current) {
      const subPath = path ? path + '.' + k : k;
      if (diffRecursive(previous, current[k], subPath)) {
        return true;
      }
    }
    return false;
  }

  const evaluate = expressions.compile(path);
  const previousValue = evaluate(previous) || '';

  // if ((String(previousValue) !== String(current))) {
  //   console.log(path, previousValue, current);
  // }

  return (String(previousValue) !== String(current));
}
