<template>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('contact.searchCompany') }}</p>
    </header>
    <section class="modal-card-body">
      <div v-if="errorMsg" class="has-text-danger	">
        {{ errorMsg }}
      </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit" v-if="!performed">
        <ValidationProvider v-slot="{ errors, valid }" rules="" name="Name" slim>
          <ControlField label="Name*" :type="{ 'is-danger': errors[0] }" :message="errors">
            <b-input v-model="internalValue.name" name="name"></b-input>
          </ControlField>
        </ValidationProvider>

        <button type="submit" class="button is-primary" style="margin-right: 10px">
          {{ $t('common.search') }}
        </button>
      </ValidationObserver>

      <div v-else>
        <ul v-if="results.length > 0">
          <li v-for="item in results" :key="item.vat_number">
            <div class="person-card" @click="select(item)">
              <h2 class="person-card__name">{{ item.name }}</h2>
              <div class="person-card__number">{{ formatVatNumber(item.vat_number, true) }}</div>
            </div>
          </li>
        </ul>
        <div v-else>
          {{ $t('common.noResults') }}
        </div>
      </div>

    </section>
    <footer class="modal-card-foot">
      <div class="field">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button type="button" class="button" @click="forwardCancel">
                {{ $t('form.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import KboService from '@/services/KboService';
import { formatVatNumber } from '@/utils/formatVatNumber';

export default {
  name: 'ContactDialog',
  components: {
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    Service: {
      type: Object,
      default: () => KboService
    }
  },

  data() {
    return {
      internalValue: {
        name: '',
        number: ''
      },
      results: [],
      performed: false,
      errorMsg: null
    };
  },

  watch: {
    value(newValue) {
      this.internalValue = JSON.parse(JSON.stringify(this.value));
    }
  },

  created() {
    this.internalValue = JSON.parse(JSON.stringify(this.value));
    if (this.internalValue.name) {
      this.submit();
    }
  },

  methods: {
    async submit() {
      if (this.internalValue.name) {
        this.performed = true;
        this.errorMsg = '';
        try {
          const result = await this.Service.searchByCompany({ name: this.internalValue.name });
          this.results = result.data.enterprises;
        } catch (e) {
          this.errorMsg = e.message;
        }
      }
    },
    select(entity) {
      this.$emit('submit', entity);
      this.$emit('update:value', entity);
      this.$parent.close();
    },
    forwardCancel() {
      this.performed = false;
      this.$parent.close();
    },
    formatVatNumber(value, addZero) {
      return formatVatNumber(value, addZero);
    }
  }
};
</script>
