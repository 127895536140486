import CRUDService from './CRUDService';
import http from '../utils/http';

class AdminPulseService extends CRUDService {
    constructor(path) {
        super(path);
    }

    searchRelation({ accessToken, name }) {
        return http({
            url: `api/${this.path}/relation`,
            method: 'GET',
            params: {
                accessToken, name
            }
        });
    }

    async uploadDocumentd({
        admin_pulse_doc_type,
        admin_pulse_contact_field,
        accessToken,
        dossier_id,
        field_name
    }) {
        return http({
            url: `api/${this.path}/upload_doc`,
            method: 'POST',
            data: {
                accessToken,
                dossier_id,
                field_name,
                admin_pulse_doc_type,
                admin_pulse_contact_field
            }
        });
    }
}

export default new AdminPulseService('adminpulse');
