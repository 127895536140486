import CRUDService from './CRUDService';
import http from '../utils/http';

class KboService extends CRUDService {
  constructor(path) {
    super(path);
  }

  searchByCompany(params) {
    return http({
      url: `api/${this.path}/search/by_company`,
      method: 'GET',
      params: {
        name: params.name, number: params.number
      }
    });
  }

  searchByPerson({ firstname, lastname, number }) {
    return http({
      url: `api/${this.path}/search/by_person`,
      method: 'GET',
      params: {
        firstname, lastname, number
      }
    });
  }
}

export default new KboService('kbo');
