export function formatVatNumber(value, addZero = false) {
  if (!value) return value;

  value = String(value);
  value = value.replace(/[^0-9]+/g, '');

  if (value.length > 10) {
    // value = value.substr(-10);
  }
  if (value.length === 9 && addZero) {
    value = '0' + value;
  }

  const parts = [
    value.substr(0, 4),
    value.substr(4, 3),
    value.substr(7, 3)
  ].filter(val => val.length > 0);

  return parts.join('.');
}

export function formatPersonNumber(value) {
  if (!value) {
    return value;
  }

  value = String(value);
  value = value.replace(/[^0-9]+/g, '');

  if (value.length > 11) {
    // value = value.substr(-10);
  }

  const parts = [
    value.substr(0, 2),
    value.substr(2, 2),
    value.substr(4, 2),
    value.substr(6, 3),
    value.substr(9, 2),
  ].filter(
    val => val.length > 0
  );


  const p1 = [parts[0], parts[1], parts[2]].filter(i => !!i).join('.');
  const p2 = [parts[3], parts[4]].filter(i => !!i).join('.');

  return p1 + (p2 ? '-' + p2 : '');
}
