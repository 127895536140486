import CompanySearchDialog from "@/modules/contact/components/CompanySearchDialog.vue";
import AdminPulseService from "@/services/AdminPulseService";
import {cloneDeep} from "lodash";

export const AdminPulseMixin = {
    methods: {
        async searchApNumber(vat_number) {
            if (!vat_number) {
                vat_number = this.value.vat_number;
            }
            if (!vat_number) {
                return;
            }

            vat_number = vat_number.replaceAll('.', '');

            const access_token = await this.$store.dispatch('oauth/getAccessToken', {
                client_name: 'adminpulse'
            });
            const result = await AdminPulseService.searchRelation({ accessToken: access_token, name: vat_number });

            const results = result.data.results;
            if (results.length !== 1) {
                this.$alertError(this.$t('common.notFound'));
            } else {
                this.assignAp(results[0]);
            }
        },
        async searchApName() {
            const $store = this.$store;
            this.$buefy.modal.open({
                parent: this,
                component: <any>CompanySearchDialog,
                hasModalCard: true,
                props: {
                    value: this.value,
                    Service: {
                        async searchByCompany({ name }) {
                            const access_token = await $store.dispatch('oauth/getAccessToken', {
                                client_name: 'adminpulse'
                            });
                            const result = await AdminPulseService.searchRelation({ accessToken: access_token, name });

                            result.data.enterprises = result.data.results;

                            return result;
                        }
                    }
                },
                events: {
                    submit: result => {
                        this.searchApNumber(result.vat_number);
                    }
                }
            });
        },
        assignAp(result) {
            const currentData = cloneDeep(this.value)
            if (result.name) {
                currentData.name = result.name;
            }
            if (result.vat_number) {
                currentData.vat_number = result.vat_number;
            }
            if (result.address) {
                currentData.address = result.address;
            }
            if (result.address_second) {
                currentData.address_second = result.address_second;
            }
            if (result.city) {
                currentData.city = result.city;
            }
            if (result.country) {
                currentData.country = result.country;
            }
            if (result.postal_code) {
                currentData.postal_code = result.postal_code;
            }
            if (result.yearendmonth) {
                currentData.yearendmonth = result.yearendmonth;
            }
            if (result.yearendday) {
                currentData.yearendday = result.yearendday;
            }
            if (result.annualmeetingmonth) {
                currentData.annualmeetingmonth = result.annualmeetingmonth;
            }

            if (result.incorporationdate) {
                currentData.incorporationdate = result.incorporationdate;
            }

            if (result.legalsituation) {
                currentData.legalsituation = result.legalsituation;
            }
            if (result.status) {
                currentData.status = result.status;
            }

            if (result.legalform) {
                currentData.legalform = result.legalform;
            }
            if (result.enterprise_court) {
                currentData.enterprise_court = result.enterprise_court;
            }
            if (result.contribution) {
                currentData.contribution = result.contribution;
            }
            if (result.court) {
                currentData.court = result.court;
            }

            if (result.board) {
                currentData.board = result.board.map(row => {
                    return row;
                });
            }

            if (result.admin_pulse_id) {
                currentData.admin_pulse_id = result.admin_pulse_id;
            }
            if (result.admin_pulse_relation_id) {
                currentData.admin_pulse_relation_id = result.admin_pulse_relation_id;
            }

            this.onChange({ data: currentData, verified: this.verified, overwrite_synced_at: true });
        },
        async uploadAdminPulse(dossier_id, fieldName, admin_pulse_contact_field, admin_pulse_doc_type) {
            const access_token = await this.$store.dispatch('oauth/getAccessToken', {
                client_name: 'adminpulse'
            });
            const result = await AdminPulseService.uploadDocumentd({
                accessToken: access_token,
                dossier_id,
                field_name: fieldName,
                admin_pulse_contact_field,
                admin_pulse_doc_type
            });

            return result.data;
        },
    }
};
